import React from "react"
import { Link } from "gatsby"
import Button from "@material/react-button"
import CenteredLayout from "../components/CenteredLayout/CenteredLayout"

import SEO from "../components/SEO/SEO"

// Styles
import "../styles/app.scss"

const NotFoundPage = () => (
  <CenteredLayout>
    <SEO title="404: Not Found" />
    <h1>Uh... this is awkward</h1>
    <p>
      It looks like the place you were looking for is no longer here or
      there is a typo. Please check the URL for mistakes and try again.{" "}
    </p>
    <Link to="/">
      <Button raised>Go Back Home</Button>
    </Link>
  </CenteredLayout>
)

export default NotFoundPage
